<section class="content">
    <div class="container-fluid">
      <div class="block-header">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <ul class="breadcrumb breadcrumb-style ">
              <li class="breadcrumb-item">
                <h4 class="page-title">Leave Report</h4>
              </li>
              <li class="breadcrumb-item bcrumb-1">
                <a routerLink="/dashboard/main">
                  <i class="fas fa-home"></i> Home</a>
              </li>              
            </ul>
          </div>
        </div>
      </div>
    
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div style="display: flex;align-items: center;justify-content:space-between;">
                <div class="reporthsearchheader" style="display: flex;align-items: center;justify-content:flex-start;">                    
                    <!-- <div style="margin-right: 10px;">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>From date</mat-label>
                            <input matInput [matDatepickerFilter]="disableWeekEnd" [matDatepicker]="startDate" onkeydown="return false" (dateChange)="fromDateChanged('change', $event)">                           
                            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                            <mat-datepicker #startDate></mat-datepicker>                
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>To date</mat-label>
                            <input matInput [matDatepickerFilter]="disableWeekEnd" [matDatepicker]="endDate" onkeydown="return false" (dateChange)="toDateChanged('change', $event)">                           
                            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                            <mat-datepicker #endDate></mat-datepicker>                
                        </mat-form-field>
                    </div>   -->
                    <mat-form-field appearance="fill" >
                      <mat-label>Enter a date range</mat-label>
                      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date">
                        <input matEndDate formControlName="end" placeholder="End date">
                      </mat-date-range-input>                     
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
                    
                      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                    </mat-form-field>
                          
                </div>
                <div style="justify-content: space-between;">
                    <button mat-raised-button color="primary" (click)="searchData()"><mat-icon class="mat-24" aria-label="Example icon-button with a heart icon">search</mat-icon>Search</button>
                    <button mat-raised-button color="primary" (click)="clear()"><mat-icon class="mat-24" aria-label="Example icon-button with a heart icon">refresh</mat-icon>Clear</button>
                </div>
                
            </div>
           
        </div>           
      </div>
      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card">            
            <div class="body table-responsive">
              <table mat-table matTableExporter [dataSource]="dataSource2" #exporter="matTableExporter" class="w-100" id="my-table">
                <!-- No Column -->
                <ng-container matColumnDef="userName">
                  <th mat-header-cell *matHeaderCellDef> userName </th>
                  <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="leavetype">
                  <th mat-header-cell *matHeaderCellDef> leavetype </th>
                  <td mat-cell *matCellDef="let element"> {{element.leavetype}} </td>
                </ng-container>
                <!-- Gender Column -->
                <ng-container matColumnDef="datefrom">
                  <th mat-header-cell *matHeaderCellDef> datefrom </th>
                  <td mat-cell *matCellDef="let element"> {{element.datefrom| date }} </td>
                </ng-container>
                <!-- Email Column -->
                <ng-container matColumnDef="dateto">
                  <th mat-header-cell *matHeaderCellDef> dateto </th>
                  <td mat-cell *matCellDef="let element"> {{element.dateto| date }} </td>
                </ng-container>
                <!-- Address Column -->
                <ng-container matColumnDef="leavereason">
                  <th mat-header-cell *matHeaderCellDef> leavereason </th>
                  <td mat-cell *matCellDef="let element"> {{element.leavereason}} </td>
                </ng-container>             
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </div>  
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div style="display: flex;align-items: center;justify-content:center;">
            <button mat-button color="accent" (click)="exporter.exportTable('xlsx', {fileName:'assets-list', sheet: 'sheet1'})">
              <mat-icon class="mat-24" aria-label="Example icon-button with a heart icon">print</mat-icon>
              XLSX</button>
            <button mat-button color="accent" (click)="exporter.exportTable('csv')">
              <mat-icon class="mat-24" aria-label="Example icon-button with a heart icon">print</mat-icon>
              CSV</button>
            <button mat-button color="accent" (click)="exporter.exportTable('json')">
              <mat-icon class="mat-24" aria-label="Example icon-button with a heart icon">print</mat-icon>
              JSON</button>
            <button mat-button color="accent" (click)="exporter.exportTable('txt')">
              <mat-icon class="mat-24" aria-label="Example icon-button with a heart icon">print</mat-icon>
              TXT</button>
            <button mat-button color="accent" (click)="printPdf()">
              <mat-icon class="mat-24" aria-label="Example icon-button with a heart icon">print</mat-icon>
              PDF</button>
          </div>
        </div>
      </div>   
    </div>
  </section>
  
