import {HttpClient} from '@angular/common/http';
import {Component, ViewChild, OnInit} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Leaves } from 'src/app/leave/leaves.model'

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const doc = new jsPDF();



@Component({
  selector: 'app-leave-report',
  templateUrl: './leave-report.component.html',
  styleUrls: ['./leave-report.component.sass']
})
export class LeaveReportComponent implements OnInit {
  displayedColumns: string[] = ['userName', 'leavetype', 'datefrom', 'dateto', 'leavereason'];   
  dataSource: Leaves[] = [];
  httpDataSource: Leaves[] = [];
  dataSource2 = new MatTableDataSource<Leaves>(this.dataSource);
  fromDate:Date = new Date(Date.now());
  toDate:Date = new Date(Date.now());
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });


  constructor(private httpClient: HttpClient) { }
  ngOnInit() {
    this.httpClient.get<any[]>('https://test.microknots.com/leave')
      .subscribe(data => {
        this.httpDataSource = data;
        this.dataSource = data;
        this.dataSource2 = new MatTableDataSource<Leaves>(this.dataSource);
        this.dataSource2.paginator = this.paginator;
      },
        error => {
          console.log(error);
        }
      );
  }
  fromDateChanged(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log(event.value);
    this.fromDate = new Date(event.value);
    
  }
  toDateChanged(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log(event.value);
    this.toDate = new Date(event.value);
  }
  disableWeekEnd = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !== 6;
  };
  getDate(dateTime){
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${months[dateTime.getMonth()]} ${dateTime.getDate()},${dateTime.getFullYear()}`
  }
  clear(){
    this.dataSource = this.httpDataSource;
    this.dataSource2 = new MatTableDataSource<Leaves>(this.dataSource);
    this.dataSource2.paginator = this.paginator;
  }
  searchData() {      
    this.dataSource = this.httpDataSource;
    this.dataSource = this.dataSource.filter(obj => {
      var objFromDate = new Date(obj.datefrom).getTime();  
      var objToDate = new Date(obj.dateto).getTime();     
      var withinRange = objFromDate >= this.range.value.start.getTime() && objFromDate <= this.range.value.end.getTime();    
      console.log(withinRange); 
      if (withinRange) {
        return obj;
      }
    });  
    this.dataSource2 = new MatTableDataSource<Leaves>(this.dataSource);
    this.dataSource2.paginator = this.paginator;
  }
  printPdf() {
    var tbleData = [];
    var headerData = [];
    headerData.push(this.displayedColumns);

    this.dataSource.forEach(object => {   
      var tmpdata = [];
      tmpdata.push(object.userName);
      tmpdata.push(object.leavetype);
      tmpdata.push(this.getDate(new Date(object.datefrom)));
      tmpdata.push(this.getDate(new Date(object.dateto)));
      tmpdata.push(object.leavereason);    
      tbleData.push(tmpdata);
    });
   
    // autoTable(doc, { html: '#my-table',includeHiddenHtml: true })
    autoTable(doc, {
      head: headerData,
      body: tbleData,
    })
    doc.save('LeaveReport.pdf')
  };
}

