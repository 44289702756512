import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';


@Component({
  selector: 'app-configpage',
  templateUrl: './configpage.component.html',
  styleUrls: ['./configpage.component.css']
})
export class ConfigpageComponent implements OnInit {
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  cname:string;
  caddress:string;
  pf:string;
  tax:string;
  isInsuranceOpted:boolean;
  configUrl= 'https://test.microknots.com/config';
  currentConfig;
  IsWait:boolean = true;

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    this.httpClient.get<any[]>(this.configUrl)
    .subscribe(data => {
        this.currentConfig = data[0];
        this.caddress = this.currentConfig.CompanyAddress;
        this.cname = this.currentConfig.CompanyName;
        this.isInsuranceOpted = this.currentConfig.Insurance;
        this.cardImageBase64 = this.currentConfig.CompanyLogo;
        this.pf = this.currentConfig.PF,
        this.tax = this.currentConfig.Tax
        this.isImageSaved = true;
        this.IsWait = false;
    },
      error => {
        console.log(error);
        this.IsWait = false;
      }
    );
  }

  fileChangeEvent(fileInput: any) {
      this.imageError = null;
      if (fileInput.target.files && fileInput.target.files[0]) {
          // Size Filter Bytes
          const max_size = 20971520;
          const allowed_types = ['image/png', 'image/jpeg'];
          const max_height = 15200;
          const max_width = 25600;

          if (fileInput.target.files[0].size > max_size) {
              this.imageError =
                  'Maximum size allowed is ' + max_size / 1000 + 'Mb';

              return false;
          }

          if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
              this.imageError = 'Only Images are allowed ( JPG | PNG )';
              return false;
          }
          const reader = new FileReader();
          reader.onload = (e: any) => {
              const image = new Image();
              image.src = e.target.result;
              image.onload = rs => {
                  const img_height = rs.currentTarget['height'];
                  const img_width = rs.currentTarget['width'];

                  console.log(img_height, img_width);


                  if (img_height > max_height && img_width > max_width) {
                      this.imageError =
                          'Maximum dimentions allowed ' +
                          max_height +
                          '*' +
                          max_width +
                          'px';
                      return false;
                  } else {
                      const imgBase64Path = e.target.result;
                      this.cardImageBase64 = imgBase64Path;
                      this.isImageSaved = true;
                      // this.previewImagePath = imgBase64Path;
                      console.log(this.cardImageBase64);
                  }
              };
          };

          reader.readAsDataURL(fileInput.target.files[0]);
      }
  }
  removeImage() {
      this.cardImageBase64 = null;
      this.isImageSaved = false;
  }
  updateConfigDetails(){    
    this.currentConfig.CompanyName = this.cname;
    this.currentConfig.CompanyAddress = this.caddress;
    this.currentConfig.CompanyLogo = this.cardImageBase64;
    this.currentConfig.Insurance = this.isInsuranceOpted;
    this.currentConfig.Tax = this.tax;
    this.currentConfig.PF = this.pf;   
   this.UpdateConfig(this.currentConfig).subscribe();
  }
  UpdateConfig(configSettings: any): Observable<any> {
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })};   
    return this.httpClient
      .patch<any>(
        this.configUrl + "/" + this.currentConfig._id,
        JSON.stringify(configSettings),httpOptions
      );
  }
}
