<section class="content">
    <div class="container-fluid">
      <div class="spinner">
        <mat-card class="example-card"  *ngIf="IsWait">
          <mat-card-content>
            <mat-progress-spinner mode="indeterminate">
            </mat-progress-spinner>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="block-header"  *ngIf="!IsWait">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <ul class="breadcrumb breadcrumb-style ">
              <li class="breadcrumb-item">
                <h4 class="page-title">Config Settings</h4>
              </li>
              <li class="breadcrumb-item bcrumb-1">
                <a routerLink="/dashboard/main">
                  <i class="fas fa-home"></i> Home</a>
              </li>              
            </ul>
          </div>
        </div>
        <div class="card">
          <div class="body" style="display: flex;justify-content: center;">
            <button mat-raised-button color="primary" (click)="updateConfigDetails()" id="updtbtn">Update</button>
          </div>           
        </div>  
        <div class="card">
            <div class="header">
                <h4 class="page-title">Company Details</h4>
            </div>
            <div class="body" style="display: flex;justify-content: space-between;">
                <div class="col-md-3">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Company Name</mat-label>
                      <input matInput [(ngModel)] = "cname">
                      <mat-icon matSuffix>turned_in_not</mat-icon>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Company Address</mat-label>
                        <input matInput [(ngModel)] = "caddress">
                        <mat-icon matSuffix>turned_in_not</mat-icon>
                      </mat-form-field>
                    </div>                   
            </div>                        
            <!-- <div class="body" style="display: flex;justify-content: center;">
                <button mat-raised-button color="primary" (click)="updateCompanyDetails()">Update</button>
              </div>  -->
            <div class="header">
                <h4 class="page-title">Salary Details</h4>
            </div>
            <div class="body" style="display: flex;justify-content: space-between;">
                <div class="col-md-3">
                    <mat-form-field appearance="outline">
                      <mat-label>PF</mat-label>
                      <input matInput [(ngModel)] = "pf">
                      <mat-icon matSuffix>turned_in_not</mat-icon>
                    </mat-form-field>
                  </div>                  
                  <div class="col-md-3">
                    <mat-form-field appearance="outline">
                      <mat-label>Tax</mat-label>
                      <input matInput [(ngModel)] = "tax">
                      <mat-icon matSuffix>turned_in_not</mat-icon>
                    </mat-form-field>
                  </div>  
                  <div class="col-md-3">                    
                    <mat-slide-toggle [(ngModel)]="isInsuranceOpted"><h6>InsuranceOpted</h6></mat-slide-toggle>
                  </div>      

            </div>
            <!-- <div class="body" style="display: flex;justify-content: center;">
                <button mat-raised-button color="primary">Update</button>
              </div>           -->
              <div class="header">
                <h4 class="page-title">Logos</h4>
            </div>
            <div class="body" class="body" style="display: flex;justify-content: space-between;">
              <div class="col-md-3">
                <div class="form-group">
                  <fieldset class="form-group">            
                    <label>Upload Company Logo</label>
                    {{imageError}}
                    <div class="custom-file fileInputProfileWrap">
                      <input type="file" (change)="fileChangeEvent($event)" class="fileInputProfile">
                      <div class="img-space">                  
                        <img [src]="cardImageBase64" />            
                      </div>            
                    </div>
                  </fieldset>
                </div>               
              </div> 
            </div>       
        </div>       
      </div>   
    </div>
  </section>
  

